import '@dbh/date-fns-prop-types';
import { dateObjectToDefaultDateString } from '@dbh/dates';
import '@dbh/search-context-types';
import { memoize } from '@dbh/lodash-extra';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import { Map } from 'immutable';
import 'prop-types';
import 'react-immutable-proptypes';
import { makeUrlWithQuery } from '@dbh/make-api-url';
import '@dbh/generic-types';

// so we have to set one ourselves in all the cases in which (`CategoryCityPage`,
// `HdpPage`) at least initially, the searchContext is the one from `/webpages`
// API (in `HdpPage`, using the `SearchBox`, one can generate a new `searchContext`
// in the URL that includes `searchDate`, but we have to render the page the first
// time).
// TODO: warning! How does this play with SSR and the fact that the `SearchBox`
// is re-rendered on the Front End with the client's date, but this probably
// is not called again on the front end? The risk is having the list of hotels
// showing results for the server's equivalent of `Today` or `Tomorrow`, and the
// `SearchBox` pre-set to the client equivalent of `Today` or `Tomorrow`.
const makeSearchContextWithSearchDate=memoize(withConformsTo("makeSearchContextWithSearchDate",[],(a,b)=>a?a.has("searchDate")?a:a.set("searchDate",dateObjectToDefaultDateString(b)):void 0));var makeSearchContextWithSearchDate$1 = makeSearchContextWithSearchDate;

const removeLocationFromSearchContext=memoize(a=>a&&a.remove("location"));var removeLocationFromSearchContext$1 = removeLocationFromSearchContext;

const defaultOptionsMakeUrlWithSearchContext=Map(),makeUrlWithSearchContext=memoize(withConformsTo("makeUrlWithSearchContext",[],(a,b,c)=>{const d=c||defaultOptionsMakeUrlWithSearchContext,e=d.get("removeLocation")?removeLocationFromSearchContext$1(b):b,f=d?d.get("additionalQueryParameters"):void 0;let g=Map({searchContext:e});return f&&(g=g.merge(f)),makeUrlWithQuery(a,{queryParameters:g})}));/**
 * Returns the given `URL` with the "search context" in the query. It also
 * includes additional parameters in the query, if they are passed in `options`.
 * @param {string} urlWithoutQuery The source `URL`. It should not have the query.
 * @param {Immutable.Map} searchContextParam .
 * @param {Immutable.Map?} options .
 * @param {Immutable.Map?} options.additionalQueryParameters To be added to the
 * query, in addition to the "search context".
 * @return {string} The given `URL`, with the the computed query.
 */var makeUrlWithSearchContext$1 = makeUrlWithSearchContext;

export { makeSearchContextWithSearchDate$1 as makeSearchContextWithSearchDate, makeUrlWithSearchContext$1 as makeUrlWithSearchContext, removeLocationFromSearchContext$1 as removeLocationFromSearchContext };
