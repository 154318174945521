import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import 'prop-types';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';

/**
 * Returns a translated string, given a "translation ID" coming from the `API`
 * `GET /messages` and editable in the `admindev` ("Web Site Resources", filter
 * by `WebMessages`).
 * @param {string?} translationId Message to translate, must be in the `admindev`.
 * @param {Object?} values To replace `{}` placeholders in the translation string.
 * @param {Object?} options .
 * @param {boolean} options.limitedUseDoNotThrowWhenTranslationIdIsFalsy If `false`
 * it calls `intl.formatMessage` even if the `translationId` is falsy, resulting
 * in a thrown error (what you want in almost all cases). Otherwise it just returns
 * `undefined`, failing silently. When should this be `true`? For instance, when
 * another hook calls this hook with a computed result, a result that sometimes
 * can be falsy (hooks cannot be called conditionally, so that hook could not
 * call `useFormattedMessage` only in certain cases).
 * @return {string?} Translated message.
 */const useFormattedMessage=withConformsTo("useFormattedMessage",[],(a,b,c)=>{const{limitedUseDoNotThrowWhenTranslationIdIsFalsy:d}=c||{},e=useIntl();return useMemo(()=>!a&&d?void 0:e.formatMessage({id:a},b),[e,d,a,b])});var useFormattedMessage$1 = useFormattedMessage;

export { useFormattedMessage$1 as default };
