import PropTypes from 'prop-types';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';

/**
 * Returns the given value as a JSON string, to be stored in a cookie. Our
 * convention is always storing the JSON stringified version of an Object, that
 * has only one key named `value`.
 * @param {any} value .
 * @return {string} .
 */const getAsJsonStringToStoreAsCookieValue=withConformsTo("getAsJsonStringToStoreAsCookieValue",["value",PropTypes.any],a=>JSON.stringify({value:a}));var getAsJsonStringToStoreAsCookieValue$1 = getAsJsonStringToStoreAsCookieValue;

export { getAsJsonStringToStoreAsCookieValue$1 as getAsJsonStringToStoreAsCookieValue };
