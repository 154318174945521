import 'prop-types';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';

/*
 *
 * Constants: `@dbh/request-telemetry`.
 *
 */const COMPUTE_TIME_EXCEEDS_TOTAL_TIME_ERROR="ERR!";

/**
 * Calculates the the request telemetry data.
 * @param {Object} options .
 * @param {string?} options.dbhBeUpstreamTime The `Dbh-Be-Upstream-Time`
 * header value.
 * @param {number} options.startTime Obtained by `performance.now()` or `Date.now()`.
 * @param {number} options.endTime Obtained by `performance.now()` or `Date.now()`.
 * @return {Object} .
 */const getRequestTelemetry=withConformsTo("getRequestTelemetry",[],a=>{var b=Math.floor;const{dbhBeUpstreamTime:c,startTime:d,endTime:e}=a||{},f=e-d,g={// In node, the time returned by `performance.now()` is high resolution,
// @example `63.657472014427185`, so we use `Math.floor`. In the browser
// the output is not high resolution, so `Math.floor` won't do anything.
ms:b(f),seconds:f/1e3};let h={ms:null,seconds:null},i=h;if(c){const a=1e3*c;h={// In node, the time returned by `performance.now()` is high resolution,
// @example `63.657472014427185`, so we use `Math.floor`. In the browser
// the output is not high resolution, so `Math.floor` won't do anything.
ms:b(a),seconds:+c};const d=a>=f?COMPUTE_TIME_EXCEEDS_TOTAL_TIME_ERROR:f-a;i=d===COMPUTE_TIME_EXCEEDS_TOTAL_TIME_ERROR?{ms:COMPUTE_TIME_EXCEEDS_TOTAL_TIME_ERROR,seconds:COMPUTE_TIME_EXCEEDS_TOTAL_TIME_ERROR}:{// In node, the time returned by `performance.now()` is high resolution,
// @example `63.657472014427185`, so we use `Math.floor`. In the browser
// the output is not high resolution, so `Math.floor` won't do anything.
ms:b(d),seconds:d/1e3};}return {computeTime:h,totalTime:g,networkTime:i}});var getRequestTelemetry$1 = getRequestTelemetry;

export { COMPUTE_TIME_EXCEEDS_TOTAL_TIME_ERROR, getRequestTelemetry$1 as default };
