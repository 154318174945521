import { FE_API_IDS_PATHNAMES, FE_API_IDS } from '@dbh/api-constants';
import createCustomReactPropType from '@dbh/create-custom-react-prop-type';
import { nonBlankStringPropType } from '@dbh/generic-types';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';

/*
 *
 * Constants: `@dbh/back-end-cdn-image-url-prop-type`.
 *
 */const IGNORE_BE_CDN_IMAGE_URL_PROP_TYPE_VALIDATION_PARAM_NAME="dbh-ignore-prop-type-validation";

const{[FE_API_IDS.beCdnImagesAdapter]:beCdnImagesAdapterApiPath}=FE_API_IDS_PATHNAMES,apiBackEndCdnImageUrlPropType=createCustomReactPropType((a,b,c)=>{const d=a[b],e="Given: "+(d||"empty value");if("string"!=typeof d)return new Error("Invalid type of prop `"+b+"`, supplied to `"+c+"`, "+("expected it to be a string. "+e));let f;try{f=new URL(d);}catch(a){return new Error("Invalid type of prop `"+b+"`, supplied to `"+c+"`, "+("expected it to be a URL. "+e))}const{pathname:g,searchParams:h}=f;if("true"===h.get(IGNORE_BE_CDN_IMAGE_URL_PROP_TYPE_VALIDATION_PARAM_NAME)||g.includes(beCdnImagesAdapterApiPath))return null;const{hostname:i,protocol:j}=f;if("https:"!==j)return new Error("Invalid type of prop `"+b+"`, supplied to `"+c+"`, "+("expected it to be a URL starting with `https://`. "+e));return "cdn.daybreakhotels.com"===i?null:new Error("Invalid type of prop `"+b+"`, supplied to `"+c+"`, expected it to be an URL of the back end production CDN: "+("cdn.daybreakhotels.com. This is a recurring back end deploy bug. "+e))});var apiBackEndCdnImageUrlPropType$1 = apiBackEndCdnImageUrlPropType;

const forceBackEndCdnImageUrlToPassPropTypeCheck=withConformsTo("forceBackEndCdnImageUrlToPassPropTypeCheck",["url",nonBlankStringPropType.isRequired],a=>{return a});var forceBackEndCdnImageUrlToPassPropTypeCheck$1 = forceBackEndCdnImageUrlToPassPropTypeCheck;

export { IGNORE_BE_CDN_IMAGE_URL_PROP_TYPE_VALIDATION_PARAM_NAME, apiBackEndCdnImageUrlPropType$1 as apiBackEndCdnImageUrlPropType, forceBackEndCdnImageUrlToPassPropTypeCheck$1 as forceBackEndCdnImageUrlToPassPropTypeCheck };
