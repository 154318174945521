import { applyMiddleware, createStore, compose } from 'redux';
import { fromJS } from 'immutable';
import { routerMiddleware } from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';
import createReducer from '@dbh/create-reducer-redux';
import { REDUX_AND_SAGA_KEY, loggerReducer } from '@dbh/logger-redux';
import customMiddlewares, { validateMiddlewareOrder } from '@dbh/redux-middlewares';

const configureStore=function(a,b,c){void 0===a&&(a={}),void 0===c&&(c={});const{sagaErrorHandler:d,useLoggerInWWW:e,sentryReduxEnhancer:f}=c,g=d?{onError:d}:{},h=createSagaMiddleware(g),i=[...customMiddlewares,routerMiddleware(b)];// `Sagas` will swallow errors so we need an option to handle them.
// Create the store with two middlewares:
// 0. `customMiddlewares`: they have to be in the first position because they
// dispatch events that `redux-saga` `SAGAs` must receive and process normally.
// 1. `routerMiddleware`: syncs the location (`URL`) path to the state.
// 2. `sagaMiddleware`: makes `redux-sagas` work.
i.push(h),validateMiddlewareOrder(i,{sagaMiddleware:h});const j=[applyMiddleware(...i),...(f?[f]:[])];let k=compose;// `IS_PRODUCTION_WWW` is defined statically with `webpack.DefinePlugin`:
// in `PRODUCTION_WWW` (`https://www.daybreakhotels.com`), `composeEnhancer`
// will always be `redux` compose and the `else` code is dead code, hence it
// is removed.
const l={};// We don't want to activate the `loggerReducer` in `PRODUCTION_WWW`, unless
// its activation cookie is set from the `devbar`.
e&&(l[REDUX_AND_SAGA_KEY]=loggerReducer);const m=createStore(createReducer(l),// WARNING: everything in `initialState` becomes `Immutable`. This means
// objects are converted to `Map` and arrays to `List`. Use `fromJS` to set
// object and array values into the store in the reducers.
// @see {@link https://github.com/react-boilerplate/react-boilerplate/issues/1620}
fromJS(a),k(...j)),n=m.dispatch;// Extensions,
return m.runSaga=a=>h.run(a,{limitedUseDispatch:n}),m.injectedReducers=l,m.injectedSagas={},m.createReducer=createReducer,m};

export { configureStore as default };
