import _noop from 'lodash/noop';
import '@dbh/api-constants';
import '@dbh/create-custom-react-prop-type';
import 'prop-types';

const httpMethodPropType=_noop;var httpMethodPropType$1 = httpMethodPropType;

const httpStatusCodePropType=_noop;var httpStatusCodePropType$1 = httpStatusCodePropType;

const requestOptionsShape={};var requestOptionsShape$1 = requestOptionsShape;

const requestOptionsPropType=_noop;var requestOptionsPropType$1 = requestOptionsPropType;

export { httpMethodPropType$1 as httpMethodPropType, httpStatusCodePropType$1 as httpStatusCodePropType, requestOptionsPropType$1 as requestOptionsPropType, requestOptionsShape$1 as requestOptionsShape };
