import 'lodash/has';
import 'prop-types';
import '@dbh/with-conforms-to-for-production-www';

// Don't import { noop } from 'lodash'; as it will be transpiled to:
// `_noop` so the named export will be `_noop` instead of `noop`.
// This will lead to have `noop` as `undefined`.
const createNoop=()=>()=>null,createCustomReactPropType=createNoop;

export { createCustomReactPropType as default };
