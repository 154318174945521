import _noop from 'lodash/noop';
import { isServerSideRendering } from '@dbh/environment';
import * as sentryNode from '@sentry/node';

const isSSR=isServerSideRendering(),getSentry=()=>isSSR?sentryNode:window.Sentry||{// Fallback because `Sentry` may not have been loaded
// (@see `app/serverEntry/getHtmlDocumentScripts.js`), but the following
// functions are used in the code base. We want to avoid code trying to
// call these functions and throwing, when the library is not loaded.
captureException:_noop,captureMessage:_noop,withScope:_noop,onLoad:_noop,init:_noop,// @see {@link https://github.com/getsentry/sentry-javascript/blob/master/packages/react/src/redux.ts}
getCurrentScope:_noop,addBreadcrumb:_noop,getClient:_noop,getGlobalScope:_noop},Sentry=getSentry();

export { Sentry as default };
