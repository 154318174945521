import Sentry from '@dbh/sentry';
import invariant from 'invariant';

/**
 * Prints well formatted console errors for groups of invalid values.
 * Doesn't throw.
 * @param {Immutable.List<Immutable.Map>} invalidGroup Contains items with invalid values.
 * @param {string} key The key of the invalid value.
 * @param {string} message Error message shown as the header of the error group.
 */const consoleWarningGroup=()=>{};var consoleWarningGroup$1 = consoleWarningGroup;

/**
 * Doesn't throw, but outputs a `console.error` message and notifies `sentry`.
 * @param {boolean} condition If not satisfied, print error message.
 * @param {string} message Error description.
 */const invariantWarning=(a,b)=>{invariant(!!b,"(`invariantWarning`) `"+b+"`."),a||(Sentry.captureException(Error(b)),console.error(b));};var invariantWarning$1 = invariantWarning;

/**
 * Reports the given message or `Error` to `sentry` and prints a console error.
 * The message printed to the console is generic in `www.daybreakhotels.com`,
 * for privacy.
 * @param {boolean} condition If truthy we don't report or log the message.
 * @param {string|Error} messageOrError .
 */const invariantErrorLog=(a,b)=>{if(a)return;const c="Internal error.";// eslint-disable-next-line no-console
console.error(c),"object"==typeof b?Sentry.captureException(b):Sentry.captureMessage(b);};var invariantErrorLog$1 = invariantErrorLog;

export { consoleWarningGroup$1 as consoleWarningGroup, invariantErrorLog$1 as invariantErrorLog, invariantWarning$1 as invariantWarning };
