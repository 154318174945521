import { reducer } from 'redux-form/immutable';
import { combineReducers } from 'redux-immutable';
import { REDUX_AND_SAGA_KEY as REDUX_AND_SAGA_KEY$4, developmentReducer } from '@dbh/development-redux';
import { REDUX_AND_SAGA_KEY as REDUX_AND_SAGA_KEY$7, GlobalStylesReducer } from '@dbh/global-styles';
import { REDUX_AND_SAGA_KEY as REDUX_AND_SAGA_KEY$2, languageProviderReducer } from '@dbh/language-provider-redux';
import { REDUX_AND_SAGA_KEY as REDUX_AND_SAGA_KEY$3, dateFnsLocalesReducer } from '@dbh/date-fns-locales-redux';
import { REDUX_AND_SAGA_KEY as REDUX_AND_SAGA_KEY$5 } from '@dbh/redux-form';
import { REDUX_AND_SAGA_KEY as REDUX_AND_SAGA_KEY$1, routeInternalsReducer } from '@dbh/redux-route-internals';
import { REDUX_AND_SAGA_KEY as REDUX_AND_SAGA_KEY$9, cancellableReduxSagaSpawnReducer } from '@dbh/cancellable-redux-saga-spawn';
import { REDUX_AND_SAGA_KEY, routeReducer } from '@dbh/routing-redux';
import { SSR_DATA_REDUX_AND_SAGA_KEY, ssrDataReducer } from '@dbh/ssr-data-redux';
import { REDUX_AND_SAGA_KEY as REDUX_AND_SAGA_KEY$8, daybreakHeroReducer } from '@dbh/daybreak-hero-redux';
import { REDUX_AND_SAGA_KEY as REDUX_AND_SAGA_KEY$6, trackingScriptsManagerReducer } from '@dbh/tracking-scripts-redux';
import { REDUX_AND_SAGA_KEY as REDUX_AND_SAGA_KEY$a, universalCookieReducer } from '@dbh/universal-cookie';

/**
 * Creates the main reducer with the asynchronously loaded ones.
 * @param {Object} injectedReducers Holds injected reducers.
 * @return {Function} The reducer of the application `rootReduce`.
 */const createReducer=a=>{const b={// Needed by `react-router`.
[REDUX_AND_SAGA_KEY]:routeReducer,[REDUX_AND_SAGA_KEY$1]:routeInternalsReducer,[REDUX_AND_SAGA_KEY$2]:languageProviderReducer,[REDUX_AND_SAGA_KEY$3]:dateFnsLocalesReducer,[REDUX_AND_SAGA_KEY$4]:developmentReducer,// @see {@link https://redux-form.com/8.2.2/examples/immutable/}
[REDUX_AND_SAGA_KEY$5]:reducer,[REDUX_AND_SAGA_KEY$6]:trackingScriptsManagerReducer,[SSR_DATA_REDUX_AND_SAGA_KEY]:ssrDataReducer,[REDUX_AND_SAGA_KEY$7]:GlobalStylesReducer,[REDUX_AND_SAGA_KEY$8]:daybreakHeroReducer,[REDUX_AND_SAGA_KEY$9]:cancellableReduxSagaSpawnReducer,[REDUX_AND_SAGA_KEY$a]:universalCookieReducer,// Do NOT add any initial reducers here for injected reducers, otherwise
// in PRODUCTION (result of "yarn build" and then "yarn start:prod")
// everything breaks because the initial state passed to reducers
// is the empty one defined here. Instead we want the initial state for
// each injected reducer to be te one defined on top of the reducers
// themselves: this way the selectors work as expected and there are no
// fatal exceptions related to `.get` or `.getIn` expressions.
...a},c=combineReducers(b);// @see {@link https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store/35641992#35641992}
return c};

export { createReducer as default };
