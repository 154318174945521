import _noop from 'lodash/noop';
import 'prop-types';
import '@dbh/booking-form-constants';
import '@dbh/generic-types';
import '@dbh/hotels-types';
import 'react-immutable-proptypes';
import '@dbh/date-fns-prop-types';
import '@dbh/countries-types';
import '@dbh/user-area-redux';
import '@dbh/shopping-carts-types';

const bookingUserImmutablePropType=_noop;

const bookingAttributesRootLevelImmutablePropTypeNotRequired=_noop;const frontEndBookingAttributesImmutablePropType=_noop;const bookingImmutablePropType=_noop;const userBookingImmutablePropType=_noop;const idPaymentMethodPropType=_noop;

export { bookingAttributesRootLevelImmutablePropTypeNotRequired, bookingImmutablePropType, bookingUserImmutablePropType, frontEndBookingAttributesImmutablePropType, idPaymentMethodPropType, userBookingImmutablePropType };
