import { reduxFormEventManagerReduxMiddleware } from '@dbh/redux-form';
import { locationChangeReduxMiddleware } from '@dbh/routing-redux';
import { userReduxMiddleware } from '@dbh/user-area-redux';
import 'prop-types';
import 'invariant';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';

/**
 * Check the order of the `store` middleware functions.
 * @param {Array} middlewares Will be applied to the store.
 * @param {Object} middlewaresRefs Contains the middlewares functions references.
 * @throws If the order of the middlewares is not valid.
 */const validateMiddlewareOrder=withConformsTo("validateMiddlewareOrder",[],(a,b)=>{});var validateMiddlewareOrder$1 = validateMiddlewareOrder;

const middlewares=[locationChangeReduxMiddleware,reduxFormEventManagerReduxMiddleware,userReduxMiddleware];// Load here middlewares that are meant to be disabled in `www`.

export { middlewares as default, validateMiddlewareOrder$1 as validateMiddlewareOrder };
