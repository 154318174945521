import 'invariant';
import PropTypes from 'prop-types';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';

const composeThemeModificationsFunctions=function(){for(var a=arguments.length,b=Array(a),c=0;c<a;c++)b[c]=arguments[c];return withConformsTo("composeThemeModificationsFunctions#curried",["theme",PropTypes.object.isRequired],a=>b.reduce((b,c)=>({...b,...c(a)}),a))};/**
 * Given functions that take the theme and return a modified version, returns a
 * function that takes a `styled-components` theme, passes it to every modify
 * function and returns a theme with all the results assigned to it.
 * @param {Function[]} modifyThemeFunctions .
 * @return {Function} .
 */var composeThemeModificationsFunctions$1 = composeThemeModificationsFunctions;

export { composeThemeModificationsFunctions$1 as composeThemeModificationsFunctions };
