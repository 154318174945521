import _noop from 'lodash/noop';
import 'prop-types';

/*
 *
 * Constants: `@dbh/cookie-keys`.
 *
 */// The keys of the cookies we use in this app. If you add a new cookie, be sure
// to add its key here and import it from here. Remember that we define one
// "setter" and one "getter" function for each cookie, generally located in the
// module that uses such cookie, in a file called `cookies.js`. Please do not
// create duplicates. @see `@dbh/cookies`.
const COOKIE_KEYS=Object.freeze({ANONYMOUS_ID:"dbh-anonymousId",GUID_SESSION:"dbh-guidsession",USER_DATA:"dbh-user-data",UTM:"dbh-utm",USE_LOGGER_IN_WWW:"dbh-use-logger-in-www",SEARCH_FILTERS_HINT_HIDDEN:"dbh-search-filters-hint-hidden",FORCED_REQUEST_DATA:"dbh-forced-request-data",FRONT_END_API_ENV:"dbh-front-end-api-env",FRONT_END_API_DOMAIN:"dbh-front-end-api-domain",ONE_TRUST_CONSENT:"OptanonConsent",ONE_TRUST_ALERT_BOX_CLOSED:"OptanonAlertBoxClosed"});

const cookieKeyPropType=_noop;

export { COOKIE_KEYS, cookieKeyPropType };
