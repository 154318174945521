import _noop from 'lodash/noop';
import 'prop-types';

/*
 *
 * Constants: `@dbh/generic-redux-constants`.
 *
 */const LOADING_STATUS=Object.freeze({LOADED:"LOADED",LOADING:"LOADING",NONE:"NONE",LOAD_ERROR:"LOAD_ERROR"});

const loadingStatusPropType=_noop;

export { LOADING_STATUS, loadingStatusPropType };
