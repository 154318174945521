import { createSelector } from 'reselect';
import 'prop-types';
import 'react-immutable-proptypes';
import { getIn } from 'immutable';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const useMakeSelect=withConformsTo("useMakeSelect",[],/* eslint-disable @dbh/reselect-extra/use-make-select-hook */a=>useSelector(useMemo(a,[a]))/* eslint-enable @dbh/reselect-extra/use-make-select-hook */);var useMakeSelect$1 = useMakeSelect;

/**
 * Returns `true` if the argument is truthy.
 * @param {any} value The input value.
 * @return {boolean} Is `value` truthy.
 */const toBoolean=a=>!!a||!1,createSimpleSelectorBase=withConformsTo("createSimpleSelectorBase",[],(a,b,c)=>createSelector(a,a=>{let d;return a&&(d=getIn(a,b)),c?c(d):d}));/**
 * Factory that returns a selector that given an input selector and a select path,
 * returns `undefined` if the selector has returned a falsy value otherwise
 * returns `getIn(stateSlice, selectPath)`. This function is not exposed
 * because we don't want to encourage using `fnTransform` too much, instead of
 * the standard `memoized` selector cascade with `reselect`.
 * @param {Function} stateSliceSelector The selector.
 * @param {Array|Immutable.List} selectPath The "path" to be passed to `getIn`.
 * @param {Function?} fnTransform If passed, it is called with the selected value
 * as the first argument, and we return what it returns.
 * @return {any?} The value to be selected.
 * @example
 * ```js
 * import { fromJS } from 'immutable';
 *
 * const appState = fromJS({ a: { b: { c: 1 } } });
 * const getA = (state) => state.get('a');
 *
 * const getC = createSimpleSelector(getA, ['b', 'c']);
 *
 * console.log(getC(appState)); // Prints `1`.
 * console.log(getC(Map())); // Prints `undefined`.
 * ```
 * @see {@link https://facebook.github.io/immutable-js/docs/#/Map/getIn}
 */// This just calls `createSimpleSelectorBase`.
// @see `createSimpleSelectorBase`.
const createSimpleSelector=(a,b,c)=>createSimpleSelectorBase(a,b,c);// This calls `createSimpleSelectorBase` and transforms the result to a `boolean`.
// @see `createSimpleSelectorBase`. Must always return a `boolean`, never
// `undefined`.
const createSimpleBooleanSelector=(a,b)=>createSimpleSelectorBase(a,b,toBoolean);

export { createSimpleBooleanSelector, createSimpleSelector, useMakeSelect$1 as useMakeSelect };
