import _once from 'lodash/once';
import PropTypes from 'prop-types';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import _noop from 'lodash/noop';

/*
 *
 * Constants: `@dbh/breakpoints`.
 *
 */// 'xs' is from 0 to 'sm', we don't use a media query to target it: 'mobile-first'.
// WARNING: 'xxl' and 'xxxl' breakpoints are not currently supported by our components/Grid.
const breakpoints=Object.freeze({sm:567,md:768,lg:992,xl:1200,xxl:1440,xxxl:1920});const breakpointsKeys=Object.keys(breakpoints);

const getEntriesOnce=_once(()=>Object.entries(breakpoints)),getBreakpointByWidth=withConformsTo("getBreakpointByWidth",["width",PropTypes.number.isRequired],a=>{const b=getEntriesOnce(breakpoints);let c=b.find(b=>{let[,c]=b;return a<=c});if(!c){const d=b[b.length-1];a>d[1]&&(c=d);}const[d]=c;return d});/**
 * Returns the breakpoint, given the width.
 * @param {number} width .
 * @return {string} .
 */var getBreakpointByWidth$1 = getBreakpointByWidth;

const breakpointKeyPropType=_noop;

export { breakpointKeyPropType, breakpoints, breakpointsKeys, getBreakpointByWidth$1 as getBreakpointByWidth };
