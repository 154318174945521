import PropTypes from 'prop-types';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import nanoMemoize from 'nano-memoize';

const regex=/(([^.]\.)*(?:(test(-.+|\.?))|dev\d{1,})\.daybreakhotels(?:\.com|\.localhost|\.xyz)|[^.]\.workers\.dev)$/,isDBHTestDomainUrl=withConformsTo("isDBHTestDomainUrl",["urlClass",PropTypes.instanceOf(URL).isRequired],a=>{const{hostname:b}=a;return regex.test(b)});/**
 * Returns `true` if the `URL` belongs to one of our test domains, or to a
 * `Cloudflare` workers test domain.
 * @param {URL} url .
 * @return {boolean} .
 */var isDBHTestDomainUrl$1 = isDBHTestDomainUrl;

/**
 * Returns `true` if the `URL` belongs to one of our domains, either test or
 * production.
 * @param {URL} url .
 * @return {boolean} .
 */const isDBHDomainUrl=withConformsTo("isDBHDomainUrl",["urlClass",PropTypes.instanceOf(URL).isRequired],a=>{const{hostname:b}=a;return ["daybreakhotels.com","daybreakhotels.localhost"].includes(b)||b.endsWith(".daybreakhotels.com")||b.endsWith(".daybreakhotels.localhost")});var isDBHDomainUrl$1 = isDBHDomainUrl;

/**
 * Returns a regular expression that extracts the canonical `DBH` subdomain from
 * the given host.
 * @param {string[]} dbhSubdomains The known `dbh` subdomains.
 * @return {RegExp} .
 */const createRegexToGetCanonicalDbhSubdomainFromHost=withConformsTo("createRegexToGetCanonicalDbhSubdomainFromHost",[],a=>{const b=a.join("|");return new RegExp("^(?:https*://)*(.+?)(?:(?:[.](?:"+b+")*))?[.]daybreakhotels")});var createRegexToGetCanonicalDbhSubdomainFromHost$1 = createRegexToGetCanonicalDbhSubdomainFromHost;

/**
 * Returns the subdomain or second level subdomain - we call it "canonical"
 * but it's not related to the `SEO` concept - if a known `DBH` domain for the
 * current environment (test or production) is requested.
 * @param {string} host @example `testdevelop.test.daybreakhotels.com`.
 * @param {string[]} dbhSubdomains .
 * @return {string?} @example `testdevelop`.
 */const getCanonicalDbhSubDomainFromHost=nanoMemoize(withConformsTo("getCanonicalDbhSubDomainFromHost",[],(a,b)=>{const c=b.filter(a=>a.startsWith("test-")||["test","dev"].includes(a)),d=createRegexToGetCanonicalDbhSubdomainFromHost$1(c),e=a.match(d);if(e){const[,a]=e;return a}}));var getCanonicalDbhSubDomainFromHost$1 = getCanonicalDbhSubDomainFromHost;

/**
 * Returns `true` if the given subdomain is one that we use internally in
 * the company, and as such, can't possibly be a daybreak hero subdomain.
 * @param {string[]} knownNonDaybreakHeroSubdomains @example `www`, `test`, `test-production`,
 * `test-montalcini` and others.
 * @param {string} subDomain @example `testdevelop`, `test`, `test-production-abramov`.
 * @return {boolean} .
 */const isKnownNonDaybreakHeroSubdomain=withConformsTo("isKnownNonDaybreakHeroSubdomain",[],(a,b)=>a.includes(b));var isKnownNonDaybreakHeroSubdomain$1 = isKnownNonDaybreakHeroSubdomain;

export { createRegexToGetCanonicalDbhSubdomainFromHost$1 as createRegexToGetCanonicalDbhSubdomainFromHost, getCanonicalDbhSubDomainFromHost$1 as getCanonicalDbhSubDomainFromHost, isDBHDomainUrl$1 as isDBHDomainUrl, isDBHTestDomainUrl$1 as isDBHTestDomainUrl, isKnownNonDaybreakHeroSubdomain$1 as isKnownNonDaybreakHeroSubdomain };
