import 'prop-types';
import 'react-immutable-proptypes';
import '@dbh/generic-types';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';

/**
 * Creates and returns an hash code that can be used as a cache key for `API`
 * calls.
 * @param {Immutable.Map?} userAreaData .
 * @return {number?} .
 */const createApiCallHash=withConformsTo("createApiCallHash",[],a=>{const b=a&&a.get("user");if(!b)return;const c=a.get("userPermissions"),d=b.get("allowNewsletters");return c.hashCode()+d});

export { createApiCallHash as default };
